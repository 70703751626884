<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getStaffPayList" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담사 급여내역</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="clear">
                            <a class="btn_new2 pointer float-right" @click="excelDownload()">엑셀 다운로드</a>
                        </div>
                        <div class="float-left dp-block mb-10">
                            <select  v-model="idxCrmCenter" @change="getStaffPayList()" class="font-16 w-120px h-40px pl-10">
                                <option value="-1">센터선택</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>

                            <input type="text" @keyup.enter="getStaffPayList()" v-model="name" class="ml-20 w-200px h-40px pd-00 pl-20" placeholder="상담사명">
                            <a @click="getStaffPayList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>기간검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getStaffPayList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>상담사명</th>
                                <th>상담회기</th>
                                <th>매출액<br>(미수금포함)</th>
                                <th>총 급여</th>
                                <th>소득세</th>
                                <th>지방소득세</th>
                                <th>실지급액</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="8">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <!-- <th>No</th> -->
                                <th>상담센터</th>
                                <th>상담사명</th>
                                <th>상담회기</th>
                                <th>매출액<br>(미수금포함)</th>
                                <th>총 급여</th>
                                <th>소득세</th>
                                <th>지방소득세</th>
                                <th>실지급액</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of payList" :key="index">
                                <!-- <td>{{item.rownum}}</td> -->
                                <td class="underline pointer" @click="detailCenterInfo(item.idxCrmCenter)">{{item.centerName}}</td>
                                <td class="underline pointer" @click="detailStaffInfo(item.idx)">{{item.staffName}}</td>
                                <td>{{item.reportCnt}}</td>
                                <td>{{numberWithCommas(item.price + item.etcPrice)}}</td>
                                <td>{{numberWithCommas(item.staffPay)}}</td>
                                <td>{{numberWithCommas(item.incomeTax)}}</td>
                                <td>{{numberWithCommas(item.localIncomeTax)}}</td>
                                <td>{{numberWithCommas(item.realStaffPay)}}</td>
                                <td class="underline pointer" @click="detail(item.idx)">상세정보</td>
                            </tr>
                            <tr v-if="payList.length === 0">
                                <td colspan="8">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import FileSaver from 'file-saver'
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        idxCrmCenter: -1,
        name: '',

        payList: [],
        
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {

        this.getCenterListAll()

        if(sessionStorage.getItem('origin_salary_pageNum') !== 'undefined' && sessionStorage.getItem('origin_salary_pageNum') !== null) {
            this.pageNum = sessionStorage.getItem('origin_salary_pageNum')
        }
        if(sessionStorage.getItem('origin_salary_name') !== 'undefined' && sessionStorage.getItem('origin_salary_name') !== null) {
            this.name = sessionStorage.getItem('origin_salary_name')
        }
        if(sessionStorage.getItem('origin_salary_center') !== 'undefined' && sessionStorage.getItem('origin_salary_center') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('origin_salary_center')
        }
        if(sessionStorage.getItem('origin_salary_startDate') !== 'undefined' && sessionStorage.getItem('origin_salary_startDate') !== null) {
            this.startDate = sessionStorage.getItem('origin_salary_startDate')
        }
        if(sessionStorage.getItem('origin_salary_endDate') !== 'undefined' && sessionStorage.getItem('origin_salary_endDate') !== null) {
            this.endDate = sessionStorage.getItem('origin_salary_endDate')
        }
        this.getStaffPayList();
    },

    methods: {

        detailCenterInfo(idx){
            this.$router.push(`/origin/center_manage_info?idx=${idx}`)
        },

        //상담사 급여 내역 엑셀다운로드
        excelDownload(){
            let headers = {
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            }

            let params = {
                idxCrmCenter : this.idxCrmCenter,
                name : this.name,
                startDate : this.startDate,
                endDate : this.endDate
            }
            this.axios.defaults.headers.post[headers]
            this.axios.get('/api/v1/excel/salary-list', {
                params: params,
                responseType: 'arraybuffer'
            }).then(res => {
                FileSaver.saveAs(new Blob([res.data]), "salary_list_excel.xlsx")
            }).catch(err => {
                console.log(err)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getStaffPayList() {

            let params= {
                idxCrmCenter: this.idxCrmCenter
                , name: this.name
                , startDate: this.formatDate(this.startDate)
                , endDate: this.formatDate(this.endDate)
                , pageSize: this.pageSize
                , pageNum: this.pageNum
            }

            sessionStorage.setItem('origin_salary_pageNum', this.pageNum)
            sessionStorage.setItem('origin_salary_name', this.name)
            sessionStorage.setItem('origin_salary_center', this.idxCrmCenter)
            sessionStorage.setItem('origin_salary_startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('origin_salary_endDate', this.formatDate(this.endDate))

            this.loading = true;

            this.axios.get('/api/v1/pay/staff/list', {
                params: params
            })
            .then(res => {
                if (res.data.err === 0) {
                    this.payList = res.data.payList

                    if (res.data.payListSize) {
                        this.listLength = res.data.payListSize
                    }
                    this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                    this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                    this.pagePart = Math.ceil(this.pageNum / 10)

                } else {
                    this.payList = []
                }
            })
            .catch(err => {
                console.log(err.response)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            }).finally(()=>{
                this.loading = false;
            });

        },

        // 상담사 상세 급여정보 페이지로 이동
        detail (idx) {
            this.$router.push(`/origin/salary_manage_info?idx=${idx}`)
        },

        /**
         * @description : 상담사 정보 이동 
         */
        detailStaffInfo(idx){
            this.$router.push(`/origin/counselor_manage_card?idx=${idx}`)
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getStaffPayList()
            }
        },

        paging(index) {
            this.pageNum = index
            this.getStaffPayList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getStaffPayList()
            }
        },
    }
}
</script>
